import React, { useRef, useEffect } from 'react';
import {
    useAudioVideo,
    useApplyVideoObjectFit,
    useAttendeeStatus
} from 'amazon-chime-sdk-component-library-react';
import { VideoTile } from './VideoTile';

export const RemoteVideo = ({
    name,
    className,
    tileId,
    chimeAttendeeId,
    ...rest
}) => {
    const audioVideo = useAudioVideo();
    const videoEl = useRef(null);
    useApplyVideoObjectFit(videoEl);

    const { muted, videoEnabled, sharingContent } = useAttendeeStatus(chimeAttendeeId);

    useEffect(() => {
        if (!audioVideo || !videoEl.current) {
            return;
        }

        audioVideo.bindVideoElement(tileId, videoEl.current);

        return () => {
            const tile = audioVideo.getVideoTile(tileId);
            if (tile) {
                audioVideo.unbindVideoElement(tileId);
            }
        };
    }, [audioVideo, tileId]);
    
    return (
        <VideoTile
            {...rest}
            muted={muted}
            videoEnabled={videoEnabled}
            ref={videoEl}
            nameplate={name}
            className={`ch-remote-video--${tileId} ${className || ''}`}
        />
    );
};

export default RemoteVideo;