import React, { createRef, useContext, createContext } from 'react';
import { useElementAspectRatio, useRosterState, useContentShareState } from 'amazon-chime-sdk-component-library-react';
import { StyledGrid } from '../Styles/Styled';


const gridData = { usingGrid: true };
const GridContext = createContext(null);

export const VideoGrid = (props) => {
    const { size, children, layout = 'standard', ...rest } = props;

    const gridEl = createRef();
    const ratio = useElementAspectRatio(gridEl);
    const gridSize = typeof size === 'number' ? size : React.Children.count(children);

    const { roster } = useRosterState();
    const remoteUsersNumber = Object.keys(roster).length - 1;

    const isScreenShared = useContentShareState().tileId != null;
    const sharingAttendeeId = useContentShareState().sharingAttendeeId;

    const nameOfTheScreenSharer = () => {
        if (isScreenShared) {
            const attendeeId = sharingAttendeeId.replace('#content', '')
            const nameOfScreenShare = roster[attendeeId] ? roster[attendeeId].externalUserId : '';
            return (
                <span style={{
                    position: 'absolute',
                    zIndex: 1,
                    bottom: '3%',
                    right: '26%',
                    color: 'white',
                    fontFamily: 'Avenir Next'
                }}>{nameOfScreenShare}</span>
            )
        }
        else return null
    }
    return (
        <GridContext.Provider value={gridData}>
            <StyledGrid
                ref={gridEl}
                {...rest}
                size={gridSize}
                ratio={ratio}
                data-testid="video-grid"
                remoteUsers={remoteUsersNumber}
                isScreenShared={isScreenShared}
            >
                {nameOfTheScreenSharer()}
                {children}
            </StyledGrid>
        </GridContext.Provider>
    );
};

export const useGridData = () => {
    const gridData = useContext(GridContext);

    return gridData;
};

export default VideoGrid;