import React from 'react';
import {
  UserActivityProvider
} from 'amazon-chime-sdk-component-library-react';

import { StyledLayoutMeetingView, StyledContent, HeaderStyled } from '../Styles/Styled';
// import NavigationControl from '../../containers/Navigation/NavigationControl';
import { useNavigation } from '../Providers/NavigationProvider';
import MeetingDetails from '../Meeting/MeetingDetails';
import MeetingControls from '../Meeting/MeetingControls';
import useMeetingEndRedirect from '../hooks/useMeetingEndRedirect';
import MeetingMetrics from '../containers/MeetingMetrics';
import { VideoTileGrid } from '../VideoComponents/VideoTileGrid';
import { Header } from '../Pages/Header';
import { useAppState } from '../Providers/AppStateProvider';
// import EndMeetingControl from '../Meeting/EndMeetingControls';

const MeetingView = () => {
  useMeetingEndRedirect();
  const { showNavbar, showRoster } = useNavigation();
  const { localUserName, meetingId} = useAppState();

  return (
    <>
      <UserActivityProvider>
        <StyledLayoutMeetingView showNav={showNavbar} showRoster={showRoster}>
          <StyledContent>
            <MeetingMetrics />
            <Header meetingId={meetingId} />
            <VideoTileGrid
              name={localUserName}
              layout='standard'
              className="videos"
              noRemoteVideoView={<MeetingDetails />}
            />
            <MeetingControls />
          </StyledContent>
          {/*<NavigationControl />*/}
        </StyledLayoutMeetingView>
      </UserActivityProvider>
    </>
  );
};

export default MeetingView;
