import React from "react";

import {
  useControlBarContext,
  // PopOver,
  Caret,
  IconButton,
} from "amazon-chime-sdk-component-library-react";
import { StyledControlBarItem } from "../Styles/Styled";
import PopOverItem from "../VideoComponents/PopOverItem";
import PopOver from "../VideoComponents/PopOver";

export const ControlBarButton = ({
  icon,
  onClick,
  label,
  isSelected = false,
  popOver = null,
  popOverPlacement,
  popOverLabel,
  tooltipContainerId,
  tooltipContent,
  children,
  ...rest
}) => {
  const context = useControlBarContext();

  const ButtonComponent = IconButton;
  const buttonComponentProps = !!rest["data-tooltip-position"]
    ? { tooltipPosition: rest["data-tooltip-position"], tooltipContent }
    : {};

  const renderPopOver = () => (
    <PopOver
      renderButtonWrapper={(isActive, props) => (
        <IconButton
          {...props}
          icon={<Caret direction="up" />}
          label={popOverLabel || label}
          selected={isActive}
          className={`ch-control-bar-item-caret ${isActive ? "isOpen" : ""}`}
        />
      )}
      a11yLabel={label}
      className="ch-control-bar-popover"
      placement={popOverPlacement}
    >
      {popOver && popOver.length > 0
        ? popOver.map((option, index) => (
            <PopOverItem {...option} key={index} />
          ))
        : null}
      {children}
    </PopOver>
  );

  return (
    <StyledControlBarItem
      isSelected={isSelected}
      data-testid="control-bar-item"
      {...rest}
      {...context}
      popOver={popOver}
    >
      <ButtonComponent
        {...buttonComponentProps}
        onClick={onClick}
        label={label}
        icon={icon}
        className="ch-control-bar-item-iconButton"
        selected={isSelected}
      />
      {(popOver || children) && renderPopOver()}
      {context.showLabels && (
        <div className="ch-control-bar-item-label">{label}</div>
      )}
    </StyledControlBarItem>
  );
};

export default ControlBarButton;
