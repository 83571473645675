import React from 'react';
import {
    useContentShareControls,
    useContentShareState,
    ControlBarButton,
} from 'amazon-chime-sdk-component-library-react';
import ShareContent from '../VideoComponents/ShareContent';

const ContentShareControl = ({
  label = 'Content',
  pauseLabel = 'Pause',
  unpauseLabel = 'Unpause',
}) => {
  const { isLocalUserSharing } = useContentShareState();
  const {
    paused,
    toggleContentShare,
    togglePauseContentShare,
  } = useContentShareControls();

  const dropdownOptions = [
    {
      children: <span>{paused ? unpauseLabel : pauseLabel}</span>,
      onClick: togglePauseContentShare,
    },
  ];

  return (
    <>
      <ControlBarButton
        icon={<ShareContent />}
        onClick={toggleContentShare}
        label={label}
        popOver={isLocalUserSharing ? dropdownOptions : null}
      />
    </>
  );
};

export default ContentShareControl;
