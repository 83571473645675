import React from 'react';
import CameraOff from '../Resources/BCCamOff.svg';
import CameraOn from '../Resources/BCCamOn.svg';


const Camera = props => {
    const icon = props.disabled ? CameraOff : CameraOn;
    return <img src={icon} style={iconStyle} />
}

const iconStyle = {
    height: '25px',
    width: '22px'
}


export default Camera;