import React from 'react';

import {
  // ControlBarButton,
  useMeetingManager,
  useAudioInputs,
  useToggleLocalMute
} from 'amazon-chime-sdk-component-library-react';
import Microphone from '../VideoComponents/Microphone';
import ControlBarButton from './ControlBarButton'

export const isOptionActive = (meetingManagerDeviceId, currentDeviceId) => {
  if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

const AudioInputControl = ({
  muteLabel = 'Mute',
  unmuteLabel = 'Unmute',
}) => {
  const meetingManager = useMeetingManager();
  const { muted, toggleMute } = useToggleLocalMute();
  const audioInputConfig = { additionalDevices: true };
  let { devices, selectedDevice } = useAudioInputs(audioInputConfig);

  const dropdownOptions = devices.map((device) => {
    if (localStorage.getItem('selectedAudioDevice')) {
      selectedDevice = localStorage.getItem('selectedAudioDevice')
      meetingManager.selectAudioInputDevice(selectedDevice).then(() => console.log('device selected', selectedDevice)).catch(e => console.log('error in selecting device', e));
    }
    return {
      children: <span>{device.label}</span>,
      checked: isOptionActive(selectedDevice, device.deviceId),
      onClick: () => {
        localStorage.setItem('selectedAudioDevice', device.deviceId)
        return meetingManager.selectAudioInputDevice(device.deviceId)
      }
    }
  });

  return (
    <ControlBarButton
      icon={<Microphone muted={muted} />}
      onClick={toggleMute}
      label={muted ? unmuteLabel : muteLabel}
      popOver={dropdownOptions}
    />
  );
};

export default AudioInputControl;
