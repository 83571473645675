import styled, { css } from "styled-components";
import { space } from "styled-system";

export const StyledLayout = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-width: 85rem;
  padding: 2rem;
  margin: auto;

  @media (max-width: 760px) {
    border-right: unset;
    align-items: unset;
    justify-content: unset;
  }
`;

export const title = `
  text-transform: uppercase;
  font-size: 1rem !important;
  margin-bottom: 1.75rem;
`;

export const StyledPreviewGroup = styled.div`
  margin-bottom: 2.5rem;
`;

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 65rem;
  height: auto;
  padding: 1rem 0 3rem 0;

  > * {
    flex-basis: auto;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    padding: 2.5rem 0 6rem 0;

    > * {
      flex-basis: 50%;
    }

    @media (max-height: 800px) {
      padding: 2rem 0 2rem;
    }
  }
`;

export const StyledAudioGroup = styled.div`
  padding: 0 3rem 0 0;
  border-right: 0.125rem solid #e6e6e6;

  @media (max-width: 900px) {
    padding: 2rem 0 2rem 0;
    border-right: unset;
  }
`;

export const StyledVideoGroup = styled.div`
  padding: 0 0 0 3rem;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

export const StyledInputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledContent = styled.div`
  position: relative;
  grid-area: content;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;

  .videos {
    flex: 1;
  }

  .controls {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 768px) {
    .controls {
      position: static;
      transform: unset;
    }
  }
`;

export const StyledMetrics = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  min-width: 7.5rem;
  z-index: 5;

  .metric {
    white-space: nowrap;
    font-size: 0.75rem;
    margin-bottom: 0.375rem;

    &.title {
      font-weight: bold;
    }
  }
`;

export const StyledControls = styled.div`
  opacity: ${(props) => (props.active ? "1" : "1")};
  transition: opacity 250ms ease;
  width: 100%;

  @media screen and (max-width: 768px) {
    opacity: 1;
  }

  .controls-menu {
    width: 94%;
    height: 48px;
    position: relative;
    bottom: 8px;
    background-color: #1d1734;
    margin-left: 3%;
    margin-right: 3%;
  }
`;

export const StyledImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  text-color: white;
  background-color: black;
`;

export const StyledList = styled.dl`
  font-size: 1rem;

  dt {
    display: inline-block;
    margin-bottom: 0.75rem;
    margin-right: 0.5rem;

    &::after {
      content: ":";
    }
  }

  dd {
    display: inline-block;
    font-weight: 600;
  }
`;

export const StyledP = styled.p`
  padding: 1rem 1rem 1rem 0;
`;

export const StyledLayoutMeetingView = styled.main`
  height: 100vh;
  width: 100%;

  display: grid;

  .video-content {
    grid-area: content;
  }

  ${({ showNav, showRoster }) => {
    if (showNav && showRoster) {
      return `
        grid-template-columns: auto auto 1fr;
        grid-template-areas: 'nav roster content';
      `;
    }

    if (showNav) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'nav content';
      `;
    }

    if (showRoster) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'roster content';
      `;
    }

    return `
      grid-template-columns: 1fr;
      grid-template-areas: 'content';
    `;
  }}

  .nav {
    grid-area: nav;
  }

  .roster {
    grid-area: roster;
    z-index: 2;
  }

  @media screen and (min-width: 769px) {
    .mobile-toggle {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: "content";

    .nav {
      grid-area: unset;
      position: fixed;
    }

    .roster {
      grid-area: unset;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 320px;
    }
  }

  @media screen and (max-width: 460px) {
    .roster {
      max-width: 100%;
    }
  }
`;

export const HeaderStyled = styled.div`
  padding-left: 6%;
  padding-right: 6%;
`;

const gridTemplateBaseOnSize = {
  0: "",
  1: "repeat(1,1fr);",
  2: "repeat(1,1fr) / repeat(2,1fr);",
  3: "repeat(2,1fr) / repeat(2,1fr);",
  4: "repeat(2,1fr) / repeat(2,1fr);",
};

export const StyledGrid = styled.div`
  position: relative;
  display: grid;
  margin-left: 6%;
  margin-right: 6%;
  padding-top: 2px;
  overflow: auto;
  margin-bottom: 4%;
  gap: 5px;
  grid-template: ${({ remoteUsers, isScreenShared }) =>
    isScreenShared
      ? "repeat(4,1fr)/repeat(4,1fr);"
      : gridTemplateBaseOnSize[remoteUsers]};
`;

export const StyledFlex = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 75%;
  width: 88%;
  margin-left: 6%;
  padding-top: 2px;
  justify-content: center;
  overflow: auto;
  background-color: ${(props) => props.theme.videoGrid.bgd};
  margin-bottom: 4%;
`;

const ellipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const baseStyles = ({ css }) => (css ? `@media { ${css} };` : "");

const baseSpacing = (props) => space(props);

export const StyledVideoTile = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${(props) => props.theme.colors.greys.grey100};
  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: ${(props) => props.objectFit || "cover"}};
    ${(props) => {
      if (props.lastOf3rdTile) {
        return "margin-left: 50%;";
      }
    }}
  }
  
  .ch-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    background-color: papayawhip; /* TODO: figure out what this is supposed to be */
    margin-right: 0.5rem;
    flex: 0 0 1.5rem;
  }
  .ch-nameplate {
    backdrop-filter: blur(20px);
    background-color: rgba(46, 47, 52, 0.85);
    border-radius: 0.25rem;

    color: ${(props) => props.theme.colors.greys.white};
    ${(props) => {
      if (props.videoEnabled) {
        return "bottom: 0.5rem; bottom: 0.5rem; right: 0.5rem;";
      } else {
        return "top: 50%; left: 50%; transform: translate(-50%, -50%);";
      }
    }};
    
    padding: 0.5rem;
    position: absolute;
    div {
      ${ellipsis};
      display: flex;
      align-items: center;
    }
    .ch-text {
      font-size: ${(props) =>
        props.videoEnabled ? props.theme.fontSizes.text.fontSize : "2rem;"};
      font-family: 'Avenir Next';
      ${ellipsis};
      vertical-align: middle;
      margin: 0;
    }
  }
  ${baseSpacing}
  ${baseStyles}
`;

//.ch-nameplate max-width: calc(100% - 2rem);

export const StyledControlBarItem = styled.div`
  margin-left: 20px;
  margin-right: 10px;
  grid-template-rows: ${({ showLabels }) =>
    showLabels ? "1.5rem 1rem" : "1.5rem"};
  justify-items: center;
  align-items: center;

  > :first-child {
    grid-column-start: "1";
  }

  .ch-control-bar-item-iconButton {
    :hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
    .ch-icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
    }
  }

  .ch-control-bar-item-caret {
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;
    vertical-align: super;

    .ch-icon {
      width: 100%;
      height: 100%;
    }

    .Svg g {
      transform: scale(1.333);
      transform-origin: 50% 50%;
      color: white;
    }
  }

  .ch-control-bar-popover {
    background-color: inherit;
    grid-column-start: "1";
    color: ${({ theme }) => theme.controlBar.text};

    .isOpen.ch-control-bar-item-caret {
      color: ${(props) => props.theme.colors.primary.main};
    }
  }

  .ch-control-bar-item-label {
    color: ${({ theme }) => theme.controlBar.text};
    grid-row-start: 2;
    font-size: ${(props) => props.theme.fontSizes.footnote.fontSize};
    padding-top: 0.25rem;
    justify-self: center;
    grid-column: "1";
  }

  ${({ theme }) => theme.mediaQueries.max.sm} {
    justify-content: space-around;
    button ~ span {
      display: none;
    }
  }

  ${({ theme }) => theme.mediaQueries.max.xs} {
    margin: "0";
    button ~ span {
      display: none;
    }
  }
`;

export const StyledPopOverItem = styled.li`
  height: 2rem;
  position: relative;

  button,
  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    background-color: #0c0915;
    color: #ffffff;
    line-height: ${(props) => props.theme.fontSizes.text.lineHeight};
    font-size: ${(props) => props.theme.fontSizes.text.fontSize};
    padding: 0 2.5rem;
    text-decoration: none;
    outline: 0;

    &:hover,
    &:focus {
      background-color: #342e48;
      border-radius: 6px;
      color: white;
      outline: 0;
    }
  }

  a {
    width: unset;
  }

  .ch-content > * {
    ${ellipsis};
  }

  .ch-check {
    position: absolute;
    left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    top: 0.33rem;
    color: #87ff28;
  }

  &:hover .ch-check g,
  &:focus .ch-check g {
    fill: ${(props) => props.theme.popOver.active.itemText};
  }
`;

export const StyledPopOverMenu = styled.ul`
  width: fit-content;
  max-width: 22rem;
  background-color: #0c0915;
  margin: 0;
  border-radius: 10px;
  backdrop-filter: blur(1rem);
  list-style: none;
  padding: 0.5rem 0;
  box-shadow: 2px 2px 12px 4px rgba(0, 0, 0, 0.6);
  z-index: ${(props) => props.theme.zIndex.popOver};
  display: table;
  overflow: inherit;
`;

export const StyledPopOverToggle = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;
