import React, { useEffect, useRef } from 'react';
import { ContentTile } from './ContentTile';

import {
  useAudioVideo,
  useContentShareState
} from 'amazon-chime-sdk-component-library-react';

export const ContentShare = ({ className, ...rest }) => {
  const audioVideo = useAudioVideo();
  const { tileId } = useContentShareState();
  const videoEl = useRef(null);

  useEffect(() => {
    if (!audioVideo || !videoEl.current || !tileId) {
      return;
    }

    audioVideo.bindVideoElement(tileId, videoEl.current);

    return () => {
      const tile = audioVideo.getVideoTile(tileId);
      if (tile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId]);

  return tileId ? (
    <ContentTile
      objectFit="contain"
      className={className || ''}
      {...rest}
      ref={videoEl}
    />
  ) : null;
};

export default ContentShare;
