import React from 'react';
import { useState } from 'react';

export default () => {
    const [studioId, setStudioId] = useState('');
    const [studioName, setStudioName] = useState('');
    const [name, setName] = useState('');
    const [link, setLink] = useState('');

    const generateLink = () => {
        const url = `${window.location.href.replace('/encode', '')}/join-studio?studio_id=${btoa(studioId)}&studio_name=${btoa(studioName)}&user_name=${btoa(name)}&token=${btoa(studioId + studioName + name)}`
        setLink(url);
    }

    return (
        <div>
            <input placeholder="studio id" onChange={(e) => setStudioId(e.target.value)} />
            <input placeholder="studio name" onChange={(e) => setStudioName(e.target.value)} />
            <input placeholder="user name" onChange={(e) => setName(e.target.value)} />
            <button onClick={generateLink}>Generate Link</button>
            <p>{link}</p>
        </div>
    )
}
