import React from 'react';
import { Header } from '../Pages/Header';
import { useAppState } from '../Providers/AppStateProvider';
import './EndMeeting.css';

export default () => {
    const { meetingId, meetingLocation } = useAppState();

    const rejoinMeeting = () => { window.location.replace(meetingLocation) }
    return (
        <div style={{ backgroundColor: 'black', height: '100vh' }}>
            <Header meetingId='' />
            <div>
                <p className='end_text'>You have left the video call with {meetingId}</p>
                <div style={{height: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center'}}>
                    <button className='box' onClick={rejoinMeeting}>
                        <span>Rejoin the call</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
