import React from "react";

import { Flex } from "amazon-chime-sdk-component-library-react";

import { useAppState } from "../Providers/AppStateProvider";

const MeetingDetails = () => {
  const { meetingId } = useAppState();

  return (
    <Flex
      container
      layout="fill-space-centered"
      style={{ backgroundColor: "black" }}
    >
      <Flex mb="2rem" mr={{ md: "2rem" }} px="1rem">
        {meetingId}
      </Flex>
    </Flex>
  );
};

export default MeetingDetails;
