import React from 'react';
import { RemoteVideo } from './RemoteVideo';

import {
  useRemoteVideoTileState,
  useRosterState,
  useContentShareState
} from 'amazon-chime-sdk-component-library-react';

import { useAppState } from '../Providers/AppStateProvider';

export const RemoteVideos = props => {
  const { roster } = useRosterState();
  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();
  const { attendeeId } = useAppState();

  const attendeeNumber = Object.keys(roster).length
  const allAttendees = Object.keys(roster)
  const allRemote = allAttendees.filter(attendee => attendee != attendeeId);
  const camerasOnUserIds = Object.values(tileIdToAttendeeId)

  const getKeyByValue = (attendeeId) => {
    return parseInt(Object.keys(tileIdToAttendeeId).find(key => tileIdToAttendeeId[key] === attendeeId));
  }
  /**
   * 
  {tiles.map((tileId) => {
        const attendee = roster[tileIdToAttendeeId[tileId]] || {};
        console.log('attendee --- ', attendee, tileId);
        let { externalUserId } = attendee;
        if (!externalUserId) externalUserId = 'No Name'

        return (
          <RemoteVideo {...props} key={tileId} tileId={tileId} name={externalUserId} attendeeNumber={attendeeNumber} />
        );
      })}
   */
  let tileId;
  let lastOf3rdTile = false;
  const isScreenShared = useContentShareState().tileId != null;
  return (
    <>
      {allRemote.map((remoteId, key) => {
        const attendee = roster[remoteId];
        const { externalUserId, chimeAttendeeId } = attendee;

        let index = camerasOnUserIds.indexOf(remoteId);
        if (index > -1) {
          //user has its camera on
          tileId = getKeyByValue(remoteId);
        } else {
          //user does not have the camera on
          tileId = key * 100 + 100;
        }
        
        if(allRemote.length == 3 && key == 2 && !isScreenShared) 
          lastOf3rdTile = true;
        
        return (
          <RemoteVideo {...props} key={tileId} tileId={tileId} name={externalUserId} attendeeNumber={attendeeNumber} chimeAttendeeId={chimeAttendeeId} lastOf3rdTile={lastOf3rdTile} />
        );
      })}
    </>
  );
};
