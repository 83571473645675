import React from 'react';

import {
    // Camera,
    useVideoInputs,
    useLocalVideo,
    useSelectVideoInputDevice,
} from 'amazon-chime-sdk-component-library-react';
import Camera from '../VideoComponents/Camera';
import ControlBarButton from '../DeviceSetup/ControlBarButton'

const videoInputConfig = {
    additionalDevices: true,
};

const isOptionActive = (
    meetingManagerDeviceId,
    currentDeviceId
) => {
    if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
        return true;
    }
    return currentDeviceId === meetingManagerDeviceId;
};

let launchingFirstTime = true;

const VideoInputControl = ({ label = 'Video' }) => {
    const { devices, selectedDevice } = useVideoInputs(videoInputConfig);
    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const selectDevice = useSelectVideoInputDevice();

    if (!isVideoEnabled && launchingFirstTime){
        launchingFirstTime = false;
        toggleVideo();
    }
    
    const dropdownOptions = devices.map(device => ({
        children: <span>{device.label}</span>,
        checked: isOptionActive(selectedDevice, device.deviceId),
        onClick: () => selectDevice(device.deviceId),
    }));

    return (
        <ControlBarButton
            icon={<Camera disabled={!isVideoEnabled} />}
            onClick={toggleVideo}
            label={label}
            popOver={dropdownOptions}
        />
    );
};

export default VideoInputControl;