import React from 'react';

import {
  useRemoteVideoTileState,
  useFeaturedTileState,
  useContentShareState,
  useLocalVideo,
  // ContentShare,
  LocalVideo
} from 'amazon-chime-sdk-component-library-react';
import { RemoteVideos } from './RemoteVideos';
import { VideoGrid } from './VideoGrid';
import { ContentShare } from './ContentShare';

const staticStyles = `
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 4%;
  max-height: 30vh;
  height: auto;
  video {
    position: static;
    border-radius: 8px;
  }
  border-radius: 8px;
  width: 250px;
  z-index: 1;
`;

export const VideoTileGrid = ({
  noRemoteVideoView,
  layout = 'featured',
  name,
  ...rest
}) => {
  const { tileId: featureTileId } = useFeaturedTileState();
  const { tiles } = useRemoteVideoTileState();
  const { tileId: contentTileId } = useContentShareState();
  const { isVideoEnabled } = useLocalVideo();
  const featured =
    (layout === 'featured' && !!featureTileId) || !!contentTileId;

  let remoteSize = tiles.length + (contentTileId ? 1 : 0);
  
  const gridSize = remoteSize > 1 && isVideoEnabled ? remoteSize + 1 : remoteSize;
  //probably I won't need the grid size to be plus my own local video as the local video does not use grid to display
  return (
    <VideoGrid {...rest} size={gridSize} style={{ backgroundColor: 'black' }}>
      <ContentShare css="grid-column-start: 1;grid-column-end: 4;grid-row-start: 1;grid-row-end: 5;" />
      <RemoteVideos />
      <LocalVideo css={staticStyles} />
    </VideoGrid>
  );
};
