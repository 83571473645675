import React, {
    createRef,
    useState,    
    useEffect,
  } from 'react';
  import { Manager, Reference, Popper } from 'react-popper';

  import {
    useClickOutside,
    useTabOutside    
} from 'amazon-chime-sdk-component-library-react';
//   import classnames from 'classnames';
  
//   import { KEY_CODES } from '../../../constants';
import { StyledPopOverMenu, StyledPopOverToggle } from '../Styles/Styled';
  
  
  const getFocusableElements = (node) => {
    return node.querySelectorAll('button, [href]');
  };
  
  export const PopOver = ({
    renderButton,
    renderButtonWrapper,
    onPopOverClick,
    children,
    isSubMenu = false,
    placement = 'bottom-start',
    a11yLabel,
    className,
    closeOnClick = true,
    ...rest
  }) => {
    const menuRef = createRef();
    const [isOpen, setIsOpen] = useState(false);
  
    useEffect(() => {
      if (isOpen && !!menuRef.current) {
        const nodes = getFocusableElements(menuRef.current);
        !!nodes && nodes[0].focus();
      }
    }, [isOpen]);
  
    const move = (direction) => {
      const node = menuRef.current;
  
      if (isSubMenu) {
        return;
      }
      if (!!node) {
        const nodes = getFocusableElements(node);
        const currentElement = document.activeElement;
  
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i] === currentElement) {
            if (direction === 'down' && i !== nodes.length - 1) {
              return nodes[i + 1].focus();
            }
  
            if (direction === 'up' && i > 0) {
              return nodes[i - 1].focus();
            }
            break;
          }
        }
      }
    };
  
    const closePopover = (e) => {
      if (!closeOnClick) {
        return;
      }
      const isSubMenuButton = e.target.closest("[data-menu='submenu']");
      return !isSubMenuButton ? setIsOpen(false) : false;
    };
  
    // const handleKeyUp = (e) => {
    //   switch (e.keyCode) {
    //     case KEY_CODES.ESCAPE:
    //       return setIsOpen(false);
    //     case KEY_CODES.ARROW_UP:
    //       return move('up');
    //     case KEY_CODES.ARROW_DOWN:
    //       return move('down');
    //   }
    // };
  
    const handlePopOverClick = () => {
      setIsOpen(!isOpen);
      if(onPopOverClick) {
        onPopOverClick(isOpen);
      }
    };
  
    useClickOutside(menuRef, () => setIsOpen(false));
    useTabOutside(menuRef, () => setIsOpen(false));
  //onKeyDown={handleKeyUp}
    return (
      <span ref={menuRef}  data-testid="popover">
        <Manager>
          <Reference>
            {({ ref }) => {
              const props = {
                ref,
                // className: classnames(className, 'ch-popover-toggle'),
                onClick: handlePopOverClick,
                'data-menu': isSubMenu ? 'submenu' : null,
                'aria-label': a11yLabel,
                'aria-haspopup': true,
                'aria-expanded': isOpen,
                'data-testid': 'popover-toggle',
              };
  
              if (renderButton) {
                return (
                  <StyledPopOverToggle {...props}>
                    {renderButton(isOpen)}
                  </StyledPopOverToggle>
                );
              }
  
              if (renderButtonWrapper) {
                const { ref, ...rest } = props;
                return (
                  <span ref={ref}>
                    {renderButtonWrapper(isOpen, rest)}
                  </span>
                );
              }
  
              return null;
            }}
          </Reference>
          {isOpen && (
            <Popper
              placement={placement}
              modifiers={[{ name: 'offset', options: { offset: [-8, 0] } }]}
              {...rest}
            >
              {({ ref, style, placement }) => (
                <StyledPopOverMenu
                  data-placement={placement}
                  onClick={(e) => closePopover(e)}
                  ref={ref}
                  style={style}
                  data-testid="menu"
                  className="ch-popover-menu"
                >
                  {children}
                </StyledPopOverMenu>
              )}
            </Popper>
          )}
        </Manager>
      </span>
    );
  };
  
  export default PopOver;
  