import axios from 'axios';
import Config from '../config/config.json';

export async function fetchMeeting(meetingId, name, region, receivedToken = '', calculatedToken = '', studioName = '') {
    if (receivedToken != calculatedToken) {
        throw new Error('Token is invalid')
    }
    name = encodeURIComponent(name);
    const response = await axios.get(`${Config.backendUrl}/joing-meeting/${meetingId}/${name}`);
    const data = {
        meetingInfo: response.data.meetingResponse.Meeting,
        attendeeInfo: response.data.attendeeResponse.Attendee
    };
    return data;
}

export function createGetAttendeeCallback(meetingId) {
    return async (chimeAttendeeId, externalUserId) => {
        // const attendeeUrl = `${BASE_URL}attendee?title=${encodeURIComponent(
        //     meetingId
        // )}&attendee=${encodeURIComponent(chimeAttendeeId)}`;
        // const res = await fetch(attendeeUrl, {
        //     method: 'GET'
        // });

        // if (!res.ok) {
        //     throw new Error('Invalid server response');
        // }

        // const data = await res.json();

        // return {
        //     name: data.AttendeeInfo.Name
        // };
    };
}
