import React, { useContext, useState } from 'react';

const AppStateContext = React.createContext(null);

export function useAppState() {
    const state = useContext(AppStateContext);
    if (!state) {
        throw new Error('useAppState must be used within AppStateProvider');
    }

    return state;
}

const query = new URLSearchParams(window.location.search);

export function AppStateProvider({ children }) {
    const [meetingId, setMeeting] = useState(query.get('meetingId') || '');
    const [region, setRegion] = useState(query.get('region') || '');
    const [localUserName, setLocalName] = useState('');
    const [attendeeId, setAttendeeId] = useState('');
    const [theme, setTheme] = useState(() => {
        const storedTheme = localStorage.getItem('theme');
        return storedTheme || 'light';
    });
    const [meetingLocation, setMeetingLocation] = useState('');

    const toggleTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            setTheme('light');
            localStorage.setItem('theme', 'light');
        }
    };

    const setAppMeetingInfo = (
        meetingId,
        name,
        region,
        attendeeId = ''
    ) => {
        setRegion(region);
        setMeeting(meetingId);
        setLocalName(name);
        setAttendeeId(attendeeId)
    };

    const setMeetingURL = webURL => setMeetingLocation(webURL)

    const providerValue = {
        meetingId,
        localUserName,
        theme,
        region,
        attendeeId,
        meetingLocation,
        setMeetingURL,
        toggleTheme,
        setAppMeetingInfo
    };

    return (
        <AppStateContext.Provider value={providerValue}>
            {children}
        </AppStateContext.Provider>
    );
}
