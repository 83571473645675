import React from 'react';
import Logo from '../Resources/bclogo.svg';
import './MessagePage.css';
import Config from '../config/config.json';

export const MessagePage = props => {
    return (
        <div className="main-div">
            <img className="logo-style" src={Logo} />
            {props.errorMessage ? <strong className="error-style">{props.errorMessage}</strong> : null}
            <p className="text-style">{props.message}</p>
            <span>{Config.version}</span>
        </div>
    )
}