import React from 'react';

import Check from './Check';
import { StyledPopOverItem } from '../Styles/Styled';

export const PopOverItem = ({
  as = 'button',
  children,
  checked,
  ...rest
}) => {
  let Tag = as;
  return (
    <StyledPopOverItem>
      {checked && <Check className="ch-check" />}
      <Tag className="ch-content" {...rest}>
        {children}
      </Tag>
    </StyledPopOverItem>
  );
};

export default PopOverItem;
