import React from 'react';
import MicOff from '../Resources/BCMicOff.svg';
import MicOn from '../Resources/BCMicOn.svg';


const Microphone = props => {
    const icon = props.muted ? MicOff : MicOn;
    return <img src={icon} style={iconStyle} />
}

const iconStyle = {
    height: '25px',
    width: '18px'
}


export default Microphone;