import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const EndMeetingButton = () => {
  const [style, setStyle] = useState({
    boxSizing: 'border-box',
    height: '32px',
    width: '104px',
    border: '1px solid rgba(255,255,255,0.1)',
    borderRadius: '8px',
    backgroundColor: 'rgba(255,255,255,0.05)',
    color: '#FF7475',
    position: 'absolute',
    right: '3%'
  });

  const history = useHistory();
  const leaveMeeting = async () => {
    history.push('/end-meeting');
  };

  return (
    <button
      onClick={leaveMeeting} style={style}
      onMouseEnter={() => { setStyle({ ...style, backgroundColor: 'rgba(255,255,255,0.25)' }) }}
      onMouseLeave={() => { setStyle({ ...style, backgroundColor: 'rgba(255,255,255,0.05)' }) }}
    >Leave
    </button>
  );
};



export default EndMeetingButton;
