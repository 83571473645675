import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MeetingView from './Meeting/MeetingView';
import NoMeetingRedirect from './Meeting/NoMeetingRedirect';
import MeetingWithLink from './Meeting/MeetingWithLink';
import EndMeeting from './Meeting/EndMeeting';
import EncodeComp from './Meeting/EncodeComp';
import { AppStateProvider } from './Providers/AppStateProvider';
import { NavigationProvider } from './Providers/NavigationProvider';
import { ThemeProvider } from 'styled-components';
import { MessagePage } from './Pages/MessagePage';
import { MeetingProvider, lightTheme } from 'amazon-chime-sdk-component-library-react';

const App = () => (
  <Router>
    <AppStateProvider>
      <ThemeProvider theme={lightTheme}>
        <MeetingProvider>
          <NavigationProvider>
            <Switch>
              <Route exact path="/">
                <MessagePage message="Please use the BeatConnect app" />
              </Route>
              <Route path="/meeting">
                <NoMeetingRedirect>
                  <MeetingView />
                </NoMeetingRedirect>
              </Route>
              <Route exact path="/join-studio">
                <MeetingWithLink />
              </Route>
              <Route exact path="/end-meeting">
                <EndMeeting />
              </Route>
              <Route exact path="/encode">
                <EncodeComp />
              </Route>
            </Switch>
          </NavigationProvider>
        </MeetingProvider>
      </ThemeProvider>
    </AppStateProvider>
  </Router>
)

export default App;
