import React, { forwardRef } from 'react';
import { StyledVideoTile } from '../Styles/Styled';
import MicOff from '../Resources/iconMicOff.svg';

export const VideoTile = forwardRef((props, ref) => {
  const { tag, className, nameplate, muted, videoEnabled, lastOf3rdTile, ...rest } = props;

  return (
    <StyledVideoTile
      as={tag}
      className={className || ''}
      data-testid="video-tile"
      muted={muted} 
      videoEnabled={videoEnabled}
      lastOf3rdTile={lastOf3rdTile}
      {...rest}
    >
      <video ref={ref} className="ch-video" />
      {nameplate && (
        <header className="ch-nameplate">
          <span className="ch-text">{nameplate}</span>
          {muted && (<img src={MicOff} style={{marginLeft: '15px', verticalAlign: 'middle'}} />)}
        </header>
      )}
      
    </StyledVideoTile>
  );
}
);

export default VideoTile;
