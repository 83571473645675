import React from 'react';
import { HeaderStyled } from '../Styles/Styled';
import Logo from '../Resources/bclogo.svg';
import classes from './Header.module.css';

export const Header = props => {
    return (
        <HeaderStyled className={classes.header}>
            <div>
                <img src={Logo} alt="BC Logo" style={{ height: '40px' }} />
            </div>
            <div className={classes.title}>
                {props.meetingId}
            </div>
        </HeaderStyled>
    )
}