import React from 'react';

const Svg = ({
  className,
  children,
  viewBox = '0 0 24 24',
  xmlns = 'http://www.w3.org/2000/svg',
  width,
  height,
  title,
  ...otherProps
}) => {
  const styles = {
    width: width,
    height: height,
  };

  return (
    <svg
      xmlns={xmlns}
      className={`Svg ${className || ''}`}
      height={height}
      style={styles}
      viewBox={viewBox}
      width={width}
      {...otherProps}
    >
      {title && <title>{title}</title>}
      <g fillRule="evenodd" fill="currentColor">
        {children}
      </g>
    </svg>
  );
};

export default Svg;
