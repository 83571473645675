import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    useMeetingManager
} from 'amazon-chime-sdk-component-library-react';
import { useAppState } from '../Providers/AppStateProvider';
import { fetchMeeting } from '../APIs/apis';
import { MessagePage } from '../Pages/MessagePage';

const MeetingWithLink = () => {
    let searchQueryhash = '';
    if(window.location.hash) {
        const hash = window.location.hash.replace('#/join-studio?', '')
        searchQueryhash = new URLSearchParams(hash);
    } else {
        searchQueryhash = new URLSearchParams(window.location.search);
    }
    
    const studioId = searchQueryhash.get('studio_id');
    const studioName = searchQueryhash.get('studio_name');
    const userName = searchQueryhash.get('user_name');
    const token = searchQueryhash.get('token');

    const studioIdBase64Decoded = atob(studioId);
    const studioNameBase64Decoded = atob(studioName);
    const userNameBase64Decoded = atob(userName);

    let attendeeId; 

    const tokenEncoded = btoa(studioIdBase64Decoded + studioNameBase64Decoded + userNameBase64Decoded)

    //Do validation here
    const meetingManager = useMeetingManager();
    const { setAppMeetingInfo, setMeetingURL } = useAppState();
    
    const history = useHistory();
    useEffect(() => {
        fetchMeeting(studioIdBase64Decoded, userNameBase64Decoded, 'region', token, tokenEncoded, studioNameBase64Decoded)
            .then(joinInfo => {
                attendeeId = joinInfo.attendeeInfo.AttendeeId;
                setMeetingURL(window.location.href)
                return meetingManager.join(joinInfo)
            })
            .then(() => {
                setAppMeetingInfo(studioNameBase64Decoded, userNameBase64Decoded, 'region', attendeeId);
                return meetingManager.start();
            })
            .then(data => {
                history.replace(`/meeting/${studioIdBase64Decoded}`);
            })
            .catch(e => console.log(e))
    }, [])

    if (token != tokenEncoded) {
        return <MessagePage message="We haven’t able to connect you to the video call…"
            errorMessage="Something went wrong..." />
    } else {
        return <MessagePage message="Just a moment… We’re letting you in." />
    }
};


export default MeetingWithLink;
