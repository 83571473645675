import React from 'react';
import ShareContentIcon from '../Resources/BCShareScreen.svg';

const ShareContent = () => {
    return <img src={ShareContentIcon} style={iconStyle} />
}

const iconStyle = {
    height: '25px',
    width: '22px'
}


export default ShareContent;